.root {
  position: relative;
  display: inline-block;
}

.selector {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
    text-transform: uppercase;
  }
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 160px;
  z-index: 1000;
  padding: 4px;
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #333;
  text-align: left;

  &:hover {
    background: #f5f5f5;
    border-radius: 4px;
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
    text-transform: uppercase;
  }

  .checkmark {
    margin-left: auto;
    color: #1890ff;
  }
}
